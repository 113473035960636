@banner5: banner5;

.@{banner5} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 100vh;
  position: relative;
  background: url('../../images/about-bg.png') center;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;

  & &-page {
    padding: 1px 0 0;
  }

  &-title {
    font-size: 20px;
    font-family: 'KaiTi';
    font-family: 'STKaiTi';
    color: rgb(233, 229, 205);
    line-height: 40px;
    text-shadow: 0px 2px 4px rgba(28, 20, 36, 0.15);
    -moz-transform: matrix(2.19640974433988, 0, 0, 2.19640974433988, 0, 0);
    -webkit-transform: matrix(2.19640974433988, 0, 0, 2.19640974433988, 0, 0);
    -ms-transform: matrix(2.19640974433988, 0, 0, 2.19640974433988, 0, 0);
    margin-bottom: 20px;
    text-align: center;

    &-wrapper {
      width: 40%;
      max-width: 450px;
      position: relative;
      top: 30%;
      left: 24px;
      font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB',
        'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol';
    }
  }

  &-explain {
    color: #333;
    font-size: 65px;
    font-family: 'KaiTi';
    font-family: 'STKaiTi';
    color: rgb(239, 221, 184);
    line-height: 40px;
    text-align: center;
    margin-left: 150px;
    margin-bottom: 24px;
  }

  &-content {
    font-size: 18px;
    font-family: 'MicrosoftYaHeiUI';
    text-align: center;
    line-height: 20px;
    color: rgb(174, 133, 74);
    margin-left: 155px;
    margin-bottom: 48px;
  }

  &-button-wrapper {
    .@{banner5}-button {
      background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
      box-shadow: 0 8px 24px fade(#5f9bf1, 35);
      border-radius: 20px;
      border: none;
      padding: 0 42px;
      line-height: 40px;
      height: 40px;
      transition: transform 0.3s, box-shadow 0.3s;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 13px 24px fade(#5f9bf1, 45);
        background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
      }
    }
  }

  &-image {
    overflow: hidden;
    width: 40%;
    max-width: 710px;
    position: absolute;
    top: 70px;
    right: 14px;

    .box {
      background-color: rgb(11, 11, 11);
      opacity: 0.502;
      height: 100vh;
      width: 379px;
    }

    .box-image {
      position: absolute;
      top: 0;
      width: 379px;
    }

    .box-text {
      position: absolute;
      top: 290px;
      width: 382px;
      padding: 40px;
    }
  }

  &-text {
    font-size: 16px;
    color: rgb(255, 255, 255);
    line-height: 1.75;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 767px) {
  .@{banner5} {
    min-height: 500px;

    & &-page.home-page {
      padding-top: 1px;
      padding-bottom: 0;
    }

    &-title-wrapper {
      text-align: center;
      position: static;
      width: 100%;
      margin-top: 64px;
      left: 0;
      top: 0;
    }

    &-title {
      font-size: 16px;
      line-height: 16px;
    }

    &-explain {
      font-size: 22px;
      line-height: 16px;
    }

    &-content {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 20px;
    }

    &-image {
      position: static;
      width: 100%;
      top: 0;
      right: 0;
      margin: 32px 0 24px;
    }
  }
}

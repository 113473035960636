.business-modal-background {
  border-style: solid;
  border-width: 0.1px;
  border-color: rgb(193, 153, 66);
  background-color: #111018;
  // opacity: 0.91;
}

.ant-modal {
  max-width: 100vw;
}

.ant-modal-content {
  background-color: #111018;
  color: #fff;
}

.ant-modal-close-x {
  color: #fff;
}

.business-modal-row {
  margin: 10px 60px;

  .modal-col {
    margin: 5px 0px;
  }

  .prod-title {
    font-size: 48px;
    font-family: 'KaiTi';
    font-family: 'STKaiTi';
    color: rgb(239, 221, 184);
    line-height: 0.883;
    margin-left: 80px;
    margin-bottom: 30px;
  }

  .prod-desc {
    font-size: 30px;
    font-family: 'Adobe Heiti Std';
    color: rgba(211, 170, 108, 0.702);
    line-height: 2.4;
    font-style: italic;
    margin-left: 80px;
  }

  .prod-desc-right {
    margin-left: 200px;
  }
}

.modal-title {
  font-size: 36px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: rgb(255, 255, 255);
  line-height: 0.778;
  margin-bottom: 30px;
}

.business-modal-pic-row {
  margin: 20vh 0px 0;
  text-align: center;
}
.arrow {
  width: 30px;
  padding-top: 15px;
  margin: 10px;
}

.business-modal-pic-list-item {
  margin: 25px 8px;
  width: 50px;
  height: 50px;

  img {
    object-fit: contain;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 10px;
    color: #fff;
  }
}

.ant-modal-footer {
  border: none;
  background: none;
}

.business-modal-tel-row {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(239, 221, 184);
  top: 100%;

  .business-modal-tel {
    background: url('../../images/tel-bg.png') no-repeat;
    background-size: contain;
    height: 88px;
    width: 100%;
    margin-top: 4vh;

    .business-modal-tel-img {
      background: url('../../images/tel.png') no-repeat;
      background-size: contain;
      width: 22px;
      height: 22px;
      display: inline-block;
      margin: 0 5px;
    }

    .business-modal-tel-text {
      margin-top: 10px;
      font-size: 16px;
    }
    .business-modal-tel-number {
      margin-top: 10px;
      font-size: 24px;
      font-weight: bold;
      line-height: 2;
    }
  }
}
.@{content4}-text {
  width: 100%;
  text-align: center;
  vertical-align: center;
  display: inline-block;
  margin-top: 28px;
}
.@{content4}-text:after {
  content: '';
  display: inline-block;
  text-align: center;
  opacity: 0.502;
  width: 100px;
  margin-top: 15px;
  border-bottom: 2px solid rgb(88, 66, 36);
}

.@{content4}-title {
  width: 100%;
  font-size: 36px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: rgb(239, 221, 184);
  line-height: 0.778;
}

@media screen and (max-width: 767px) {
  .business-modal-pic-row {
    margin: 2px 0px 0;

    .business-modal-pic-list-item {
      margin: 9px 2px;
      width: 80%;
      height: 30px;
      display: inline-flex;

      .business-li,
      .business-li-end {
        border: 1px solid #e5b674;
        border-radius: 18px;
        width: 15px;
        height: 15px;
        margin-top: 8px;
      }
      .business-li:after {
        content: '|';
        display: inline-block;
        text-align: left;
        opacity: 0.2;
        width: 100px;
        margin: 12px 3px;
        padding-top: 12px;
        font-size: 20px;
        color: #e5b674;
        line-height: 20px;
      }

      img {
        width: 20px;
        margin: 0 25px;
      }

      h2 {
        padding-top: 4px;
        margin: 2px 5px;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ae864a;
        line-height: 20px;
      }
    }
  }
  .arrow {
    display: none;
  }

  .business-modal-tel-row {
    .business-modal-tel {
      .business-modal-tel-img {
        width: 15px;
        height: 15px;
        margin-top: 2px;
        line-height: 1;
      }

      .business-modal-tel-text {
        font-size: 10px;
        display: none;
      }
      .business-modal-tel-number {
        font-size: 12px;
        line-height: 2;
      }
    }
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.point {
  float: left;
  width: 21px;
  height: 21px;
  opacity: 0.5;
  cursor: pointer;
  pointer-events: auto;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin: 6px auto;
  background: url('../../images/point.png') no-repeat;
  border-radius: 100%;
}
.point.active {
  opacity: 1;
}
.point-wrapper {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 21px;
  width: 21px;
  display: flex;
  height: 100%;
  align-items: center;
  pointer-events: none;
}
.point-left {
  left: 16px;
}
.point-rect {
  border-radius: 0;
}
.point-prismatic {
  border-radius: 0;
  transform: rotate(45deg);
}
.point-stroke {
  border: 2px solid #7F6543;
  background: transparent;
}
.point-small {
  width: 6px;
}
.point-small.point {
  height: 6px;
}
.point-large {
  width: 21px;
}
.point-large.point {
  height: 21px;
}
@media screen and (max-width: 767px) {
  .point-wrapper {
    display: none;
  }
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header2 .home-page {
  padding: 0 24px;
}
.header2-logo {
  display: inline-block;
  position: relative;
  width: 80px;
  line-height: 80px;
}
.header2-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  background: #191312;
  float: right;
}
.header2-menu > .menu-item {
  line-height: 62px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  color: #E5B673;
  font-family: "KaiTi";
  font-family: "STKaiTi";
  font-size: 24px;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  color: #7F6543;
  border-bottom: 2px solid #7F6543;
}
@media screen and (max-width: 767px) {
  .header2-logo {
    z-index: 101;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    background: #7F6543;
    color: #E5B673;
  }
  .header2-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .open {
    height: auto;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh);
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background: url('../../images/bg0.jpg') center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .bg1 {
  background: url('../../images/bg1.png') center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg1:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .bg2 {
  background: url('../../images/bg2.png') center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg2:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-page {
  position: relative;
  top: -40px;
  max-width: 1200px;
  margin: auto;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 14px;
  color: #E5B673;
  text-align: left;
}
.banner2-text-wrapper .banner2-title {
  left: 0;
  margin: auto;
  font-size: 56px;
  position: fixed;
  left: 10vw;
  top: 0;
  width: 400px;
  height: 100vh;
}
.banner2-text-wrapper .banner2-title .bg-box {
  opacity: 0.6;
  background-color: #0b0b0b;
  width: 100%;
  height: 100%;
}
.banner2-text-wrapper .banner2-title img {
  position: absolute;
  left: 100px;
  top: 25vh;
}
.banner2-text-wrapper .banner2-content {
  opacity: 0.6;
  width: 100vw;
}
.banner2-text-wrapper .banner2-content img {
  position: absolute;
  right: 30vw;
  width: 30vw;
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #E5B673;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner2 .bg0 {
    background: url('../../images/bg0-m.jpg') center 0 no-repeat;
    height: 100vh;
    background-size: cover;
  }
  .banner2 .bg2 {
    background: url('../../images/bg2-m.jpg') center 0 no-repeat;
    height: 100vh;
    background-size: 100% 100%;
  }
  .banner2-text-wrapper {
    width: 100%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
  .banner2-text-wrapper .banner2-title {
    width: 260px;
    height: 420px;
    margin: auto;
    position: absolute;
    top: 90vh;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .banner2-text-wrapper .banner2-title .bg-box {
    border: 1px solid #e5b673;
    opacity: 0.8;
    background-color: #0b0b0b;
    overflow: hidden;
  }
  .banner2-text-wrapper .banner2-title .bg-box .bg-box-bg {
    opacity: 0.5;
    background: inherit;
    padding: -1px;
    width: 100%;
    height: 100%;
  }
  .banner2-text-wrapper .banner2-title .bg-img {
    border: 1px solid #e5b673;
    position: absolute;
    left: 10%;
    right: 10%;
    top: 5%;
    width: 81%;
    height: 90%;
  }
  .banner2-text-wrapper .banner2-title .bg-img img {
    position: absolute;
    top: 10%;
    left: 0;
    margin: 20%;
    width: 60%;
    height: 60%;
  }
  .banner2-text-wrapper .banner2-content img {
    position: unset;
    width: 80vw;
    top: 0;
    left: 0vw;
    max-width: 456px;
  }
}
.content3-wrapper {
  height: 100vh;
  width: 100%;
  background-image: url('../../images/prod-bg.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
  padding-top: 0;
  margin-top: 15vh;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 2px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-title {
  font-size: 20px;
  font-family: 'Kaiti';
  font-family: 'STKaiti';
  color: #fff;
  text-align: right;
  position: fixed;
  padding: 0 10px;
  bottom: 0;
  right: 0;
  background-color: #110e15;
  opacity: 0.8;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-desc {
  display: none;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 15%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
.content3-wrapper .content3-block-wrapper .content3-block .btn-more {
  display: none;
}
.content3-wrapper .content3-block-wrapper .content3-block-hover:hover .content3-title {
  font-size: 22px;
  font-family: 'Kaiti';
  font-family: 'STKaiti';
  color: #fff;
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  background-color: #110e15;
  opacity: 0.8;
}
.content3-wrapper .content3-block-wrapper .content3-block-hover:hover .content3-desc {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  text-align: left;
  position: absolute;
  padding: 0 10px;
  margin: 10px;
  bottom: 40px;
  left: 0;
}
.content3-wrapper .content3-block-wrapper .content3-block-hover:hover .btn-more {
  display: inline-block;
  position: absolute;
  padding: 0 10px;
  margin: 0 20px;
  right: 0;
  bottom: 18px;
  color: #c19942;
  border: 1px solid #c19942;
  background: transparent;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 18px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
  .content3-wrapper .content3-block-wrapper .content3-block-hover:hover .content3-title {
    color: #110e15;
  }
}
.content4-wrapper {
  height: 100vh;
  width: 100%;
  background-image: url('../../images/business-bg-repeat.png');
  background-size: contain;
  background-attachment: fixed;
  background-position: center;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4 .title-content {
  text-align: center;
}
.content4-wrapper .content4 .title-h1 {
  font-size: 48px;
  font-family: 'KaiTi';
  font-family: 'STKaiti';
  color: #ffffff;
  line-height: 0.583;
}
.content4-wrapper .content4-block-wrapper {
  position: relative;
}
.content4-wrapper .content4-block-wrapper .content4-block {
  display: inline-block;
  padding: 2px;
  vertical-align: top;
  text-align: center;
}
.content4-wrapper .content4-block-wrapper .content4-block .content4-link {
  border: 1px solid #c19942;
  margin: 20px;
  background-color: #110e15;
  opacity: 0.8;
}
.content4-wrapper .content4-block-wrapper .content4-block .content4-title {
  width: 100%;
  font-size: 36px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #efddb8;
  line-height: 0.778;
}
.content4-wrapper .content4-block-wrapper .content4-block .content4-icon {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-top: 25px;
}
.content4-wrapper .content4-block-wrapper .content4-block .content4-text {
  width: 100%;
  text-align: center;
  vertical-align: center;
  display: inline-block;
  margin-top: 28px;
}
.content4-wrapper .content4-block-wrapper .content4-block .content4-text:after {
  content: '';
  display: inline-block;
  text-align: center;
  opacity: 0.502;
  width: 100px;
  margin-top: 15px;
  border-bottom: 2px solid #584224;
}
.content4-wrapper .content4-block-wrapper .content4-block.clear-both {
  clear: both;
}
.content4-wrapper .content4-block-wrapper .content4-block:hover .content4-link {
  border: 2px solid #c19942;
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    height: auto;
    min-height: 1440px;
  }
  .content4-wrapper .content4-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content4-wrapper .content4-block-wrapper .content4-block .content4-title {
    font-size: 6vw;
  }
  .content4-wrapper .content4-block-wrapper .content4-block.queue-anim-leaving {
    position: relative !important;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url('../../images/about-bg.png') center;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 20px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #e9e5cd;
  line-height: 40px;
  text-shadow: 0px 2px 4px rgba(28, 20, 36, 0.15);
  -moz-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  -webkit-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  -ms-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  margin-bottom: 20px;
  text-align: center;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 65px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #efddb8;
  line-height: 40px;
  text-align: center;
  margin-left: 150px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  font-family: 'MicrosoftYaHeiUI';
  text-align: center;
  line-height: 20px;
  color: #ae854a;
  margin-left: 155px;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 40%;
  max-width: 710px;
  position: absolute;
  top: 70px;
  right: 14px;
}
.banner5-image .box {
  background-color: #0b0b0b;
  opacity: 0.502;
  height: 100vh;
  width: 379px;
}
.banner5-image .box-image {
  position: absolute;
  top: 0;
  width: 379px;
}
.banner5-image .box-text {
  position: absolute;
  top: 290px;
  width: 382px;
  padding: 40px;
}
.banner5-text {
  font-size: 16px;
  color: #ffffff;
  line-height: 1.75;
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 16px;
    line-height: 16px;
  }
  .banner5-explain {
    font-size: 22px;
    line-height: 16px;
  }
  .banner5-content {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 20px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.banner6 {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url('../../images/about-bg.png') center;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
}
.banner6 .banner6-page {
  padding: 1px 0 0;
}
.banner6-title {
  font-size: 20px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #e9e5cd;
  line-height: 40px;
  text-shadow: 0px 2px 4px rgba(28, 20, 36, 0.15);
  -moz-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  -webkit-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  -ms-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  margin-bottom: 20px;
  text-align: center;
}
.banner6-title-wrapper {
  width: 50%;
  max-width: 634px;
  position: relative;
  top: 10vh;
  left: 72px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner6-button-wrapper .banner6-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner6-button-wrapper .banner6-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner6-big-pic img {
  position: absolute;
  max-width: 100%;
}
.banner6-image {
  overflow: hidden;
  width: 40%;
  max-width: 710px;
  position: absolute;
  top: 15vh;
  right: 10px;
}
.banner6-image .box {
  height: 100vh;
  width: 321px;
}
.banner6-image .box .box-text {
  margin: 0 20px;
}
.banner6-text {
  font-size: 37.204px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #e9e5cd;
  line-height: 1;
  text-shadow: 0px 2px 4px rgba(28, 20, 36, 0.15);
  -moz-transform: matrix(1.6127368, 0, 0, 1.6127368, 0, 0);
  -webkit-transform: matrix(1.6127368, 0, 0, 1.6127368, 0, 0);
  -ms-transform: matrix(1.6127368, 0, 0, 1.6127368, 0, 0);
  margin-bottom: 16px;
}
.banner6-content {
  font-size: 18px;
  font-family: 'MicrosoftYaHeiUI';
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  position: absolute;
  top: 55vh;
  width: 90vw;
  max-width: 1000px;
  margin: auto;
}
.banner6-content img {
  width: 10vw;
  max-width: 124px;
  height: auto;
}
.banner6-explain {
  font-size: 18px;
  font-family: 'MicrosoftYaHeiUI';
  color: #ae854a;
  line-height: 2.137;
  text-align: center;
}
.banner6-desc {
  font-size: 16px;
  font-family: 'MicrosoftYaHeiUI';
  color: #ffffff;
  line-height: 2.137;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .banner6 {
    min-height: 500px;
  }
  .banner6 .banner6-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner6-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner6-title {
    font-size: 32px;
  }
  .banner6-explain {
    font-size: 18px;
  }
  .banner6-content {
    font-size: 14px;
  }
  .banner6-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.banner7 {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url('../../images/about-bg.png') center;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
}
.banner7 .banner7-page {
  padding: 1px 0 0;
}
.banner7-title {
  font-size: 20px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #e9e5cd;
  line-height: 34px;
  text-shadow: 0px 2px 4px rgba(28, 20, 36, 0.15);
  -moz-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  -webkit-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  -ms-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  margin-bottom: 20px;
  padding-right: 140px;
  text-align: center;
}
.banner7-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 70px;
  left: 48px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner7-explain {
  color: #333;
  font-size: 65px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #efddb8;
  line-height: 40px;
  margin-left: 50px;
  margin-bottom: 24px;
}
.banner7-content {
  font-size: 18px;
  font-family: 'MicrosoftYaHeiUI';
  line-height: 20px;
  color: #ae854a;
  margin-left: 72px;
  margin-bottom: 18px;
}
.banner7-button-wrapper .banner7-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner7-button-wrapper .banner7-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner7-image {
  overflow: hidden;
  width: 40%;
  max-width: 710px;
  position: absolute;
  top: 70px;
  right: 150px;
}
.banner7-box {
  opacity: 0.0501;
  float: right;
  height: 100vh;
  width: 182px;
  height: 154px;
}
.banner7-bg {
  float: right;
}
.banner7-text {
  font-size: 14px;
  color: #ffffff;
  line-height: 1.92;
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .banner7 {
    min-height: 500px;
  }
  .banner7 .banner7-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner7-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner7-title {
    font-size: 32px;
  }
  .banner7-explain {
    font-size: 18px;
  }
  .banner7-content {
    font-size: 14px;
  }
  .banner7-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
  background-image: url('../../images/contact-bg.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 48px auto 48px;
}
.teams1-wrapper .teams1 > .title-wrapper h1 {
  font-size: 38px;
  font-family: 'STKaiTi';
  color: #b99973;
  line-height: 0.741;
  -moz-transform: matrix(1.27025797, 0, 0, 1.27025797, 0, 0);
  -webkit-transform: matrix(1.27025797, 0, 0, 1.27025797, 0, 0);
  -ms-transform: matrix(1.27025797, 0, 0, 1.27025797, 0, 0);
}
.teams1-wrapper .teams1 > .comp-wrapper {
  text-align: center;
  margin-bottom: 24px;
}
.teams1-wrapper .teams1 > .comp-wrapper .comp {
  margin: auto 20px;
  font-size: 24px;
  font-family: 'STKaiTi';
  color: #b99973;
  display: inline-block;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-text,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  font-family: 'SimSun';
  color: #ae854a;
  line-height: 3.429;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-title:after {
  content: '';
  display: block;
  opacity: 0.212;
  width: 100%;
  border-bottom: 2px solid #96879d;
}
.teams1-wrapper .teams1-text {
  margin: 8px auto;
}
.teams1-wrapper .teams1 .teams1-text {
  font-size: 18px;
  font-family: 'FZLTHJW';
  color: #b99973;
  line-height: 1.529;
}
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #b99973;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
  .teams1-wrapper .teams1 > .title-wrapper h1 {
    font-size: 6vw;
  }
}
.content10-wrapper {
  height: 216px;
  background: url('../../images/address.png') no-repeat 50%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content10-wrapper .icon-wrapper {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.content10-wrapper .icon-wrapper img {
  display: block;
}
.content10-wrapper .icon {
  position: relative;
  z-index: 1;
  animation: BeatAnim 2s ease-in-out infinite;
}
.content10-wrapper .icon-shadow {
  display: inline-block;
  position: relative;
  top: -12px;
  z-index: 0;
  animation: ScaleAnim 2s ease-in-out infinite;
  transform-origin: 50%;
}
.content10-wrapper .map-tip {
  position: absolute;
  width: 330px;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(13, 26, 38, 0.12);
  left: 50%;
  top: 50%;
  margin-left: 30px;
  margin-top: -60px;
  font-size: 14px;
  z-index: 10;
  transform-origin: 0 50%;
  text-align: left;
}
.content10-wrapper .map-tip h2 {
  font-size: 16px;
  color: #0d1a26;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .content10-wrapper {
    padding-bottom: 0;
  }
}
@keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-30px);
  }
  30% {
    transform: translateY(-15px);
  }
  40% {
    transform: translateY(-7px);
  }
}
@keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.5);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.9);
  }
}
.footer2-wrapper {
  background-color: #0d1a26;
  height: 80px;
  overflow: hidden;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #b99973;
  position: relative;
}
.footer2-wrapper .gongan-link,
.footer2-wrapper .icp-link {
  color: #b99973;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-logo {
  width: 16px;
  margin-right: 8px;
}
.footer2-wrapper .copyright-logo img {
  width: 100%;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .icp {
  float: right;
}
.footer2-wrapper .icp > * {
  display: inline-block;
}
.footer2-wrapper .icp-link {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .links {
  float: right;
}
.footer2-wrapper .gongan-link {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
}
.footer2-wrapper .gongan-link p {
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 90%;
    margin: auto;
  }
}
#Nav2_0.ke3q00azjug-editor_css {
  color: rgba(0, 0, 0, 0);
  position: fixed;
  background-color: rgba(0, 21, 41, 0);
  background-image: linear-gradient(black 0%, rgba(0, 0, 0, 0) 100%);
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
  box-shadow: none;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .ke5a3jzttsb-editor_css {
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .ke5a224zlzc-editor_css {
  margin: auto auto 0;
}
.modal-background {
  border-style: solid;
  border-width: 0.1px;
  border-color: #c19942;
  background-color: #111018;
}
.ant-modal-content {
  background-color: #111018;
  color: #fff;
}
.ant-modal-close-x {
  color: #fff;
}
.ant-modal-close-icon svg {
  width: 30px;
  height: 30px;
  color: #c19942;
}
.ant-modal {
  top: 50px;
}
.modal-row {
  margin: 10px 60px;
}
.modal-row .modal-col {
  margin: 5px 0px;
}
.modal-row .modal-col img {
  height: 70%;
  width: 70%;
  border: 1px solid #c19942;
  border-radius: 2px;
  object-fit: cover;
}
.modal-row .prod-title {
  font-size: 48px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #efddb8;
  line-height: 0.883;
  margin-left: 80px;
  margin-bottom: 30px;
}
.modal-row .prod-desc {
  font-size: 30px;
  font-family: 'Adobe Heiti Std';
  color: rgba(211, 170, 108, 0.702);
  line-height: 2.4;
  font-style: italic;
  margin-left: 80px;
}
.modal-row .prod-desc-right {
  margin-left: 200px;
}
.modal-title {
  font-size: 36px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #ffffff;
  line-height: 0.778;
  margin-bottom: 30px;
}
.modal-pic-row {
  margin: 3vh 0px 0;
  text-align: center;
  position: absolute;
  top: 55vh;
}
.modal-pic-list-item {
  margin: 8px;
  width: 182px;
  height: 158px;
  border: 1px solid #c19942;
  border-radius: 2px;
}
.modal-pic-list-item img {
  object-fit: cover;
  height: 156px;
}
.modal-pic-list-item-active {
  border: 3px solid #c19942;
}
.modal-pic-list-item-active img {
  height: 152px;
}
.modal-pic-list-left-arrow {
  width: 38px;
  height: 51px;
  margin-right: 14px;
  padding: 60px 0;
  float: right;
}
.modal-pic-list-right-arrow {
  width: 38px;
  height: 51px;
  margin-left: 14px;
  padding: 60px 0;
  float: left;
}
.content3-title {
  font-size: 20px;
  font-family: 'Kaiti';
  font-family: 'STKaiti';
  color: #fff;
  text-align: center;
  background-color: #110e15;
  opacity: 0.8;
  margin-top: -31px;
}
.business-modal-background {
  border-style: solid;
  border-width: 0.1px;
  border-color: #c19942;
  background-color: #111018;
}
.ant-modal {
  max-width: 100vw;
}
.ant-modal-content {
  background-color: #111018;
  color: #fff;
}
.ant-modal-close-x {
  color: #fff;
}
.business-modal-row {
  margin: 10px 60px;
}
.business-modal-row .modal-col {
  margin: 5px 0px;
}
.business-modal-row .prod-title {
  font-size: 48px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #efddb8;
  line-height: 0.883;
  margin-left: 80px;
  margin-bottom: 30px;
}
.business-modal-row .prod-desc {
  font-size: 30px;
  font-family: 'Adobe Heiti Std';
  color: rgba(211, 170, 108, 0.702);
  line-height: 2.4;
  font-style: italic;
  margin-left: 80px;
}
.business-modal-row .prod-desc-right {
  margin-left: 200px;
}
.modal-title {
  font-size: 36px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #ffffff;
  line-height: 0.778;
  margin-bottom: 30px;
}
.business-modal-pic-row {
  margin: 20vh 0px 0;
  text-align: center;
}
.arrow {
  width: 30px;
  padding-top: 15px;
  margin: 10px;
}
.business-modal-pic-list-item {
  margin: 25px 8px;
  width: 50px;
  height: 50px;
}
.business-modal-pic-list-item img {
  object-fit: contain;
  margin-bottom: 10px;
}
.business-modal-pic-list-item h2 {
  font-size: 10px;
  color: #fff;
}
.ant-modal-footer {
  border: none;
  background: none;
}
.business-modal-tel-row {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #efddb8;
  top: 100%;
}
.business-modal-tel-row .business-modal-tel {
  background: url('../../images/tel-bg.png') no-repeat;
  background-size: contain;
  height: 88px;
  width: 100%;
  margin-top: 4vh;
}
.business-modal-tel-row .business-modal-tel .business-modal-tel-img {
  background: url('../../images/tel.png') no-repeat;
  background-size: contain;
  width: 22px;
  height: 22px;
  display: inline-block;
  margin: 0 5px;
}
.business-modal-tel-row .business-modal-tel .business-modal-tel-text {
  margin-top: 10px;
  font-size: 16px;
}
.business-modal-tel-row .business-modal-tel .business-modal-tel-number {
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  line-height: 2;
}
.content4-text {
  width: 100%;
  text-align: center;
  vertical-align: center;
  display: inline-block;
  margin-top: 28px;
}
.content4-text:after {
  content: '';
  display: inline-block;
  text-align: center;
  opacity: 0.502;
  width: 100px;
  margin-top: 15px;
  border-bottom: 2px solid #584224;
}
.content4-title {
  width: 100%;
  font-size: 36px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #efddb8;
  line-height: 0.778;
}
@media screen and (max-width: 767px) {
  .business-modal-pic-row {
    margin: 2px 0px 0;
  }
  .business-modal-pic-row .business-modal-pic-list-item {
    margin: 9px 2px;
    width: 80%;
    height: 30px;
    display: inline-flex;
  }
  .business-modal-pic-row .business-modal-pic-list-item .business-li,
  .business-modal-pic-row .business-modal-pic-list-item .business-li-end {
    border: 1px solid #e5b674;
    border-radius: 18px;
    width: 15px;
    height: 15px;
    margin-top: 8px;
  }
  .business-modal-pic-row .business-modal-pic-list-item .business-li:after {
    content: '|';
    display: inline-block;
    text-align: left;
    opacity: 0.2;
    width: 100px;
    margin: 12px 3px;
    padding-top: 12px;
    font-size: 20px;
    color: #e5b674;
    line-height: 20px;
  }
  .business-modal-pic-row .business-modal-pic-list-item img {
    width: 20px;
    margin: 0 25px;
  }
  .business-modal-pic-row .business-modal-pic-list-item h2 {
    padding-top: 4px;
    margin: 2px 5px;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ae864a;
    line-height: 20px;
  }
  .arrow {
    display: none;
  }
  .business-modal-tel-row .business-modal-tel .business-modal-tel-img {
    width: 15px;
    height: 15px;
    margin-top: 2px;
    line-height: 1;
  }
  .business-modal-tel-row .business-modal-tel .business-modal-tel-text {
    font-size: 10px;
    display: none;
  }
  .business-modal-tel-row .business-modal-tel .business-modal-tel-number {
    font-size: 12px;
    line-height: 2;
  }
}
.about {
  width: 100%;
  min-height: 1400px;
  position: relative;
  background: url('../../images/about-bg.png') center;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
}
.about .about-page {
  padding: 1px 0 0;
}
.about-title {
  font-size: 20px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #e9e5cd;
  line-height: 40px;
  text-shadow: 0px 2px 4px rgba(28, 20, 36, 0.15);
  -moz-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  -webkit-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  -ms-transform: matrix(2.19640974, 0, 0, 2.19640974, 0, 0);
  text-align: center;
}
.about-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.about-explain {
  color: #333;
  font-size: 65px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #efddb8;
  line-height: 40px;
  text-align: center;
  margin-left: 150px;
  margin-bottom: 24px;
}
.about-content {
  font-size: 18px;
  font-family: 'MicrosoftYaHeiUI';
  text-align: center;
  line-height: 20px;
  color: #ae854a;
  margin-left: 155px;
  margin-bottom: 48px;
}
.about-image {
  overflow: hidden;
  width: 40%;
  max-width: 710px;
  position: absolute;
  top: 70px;
  left: calc(50vh - 191px);
}
.about-image .about-box {
  background-color: #0b0b0b;
  opacity: 0.502;
  height: 1200px;
  width: 100%;
}
.about-image .about-box-image {
  position: absolute;
  top: 0;
  width: 100%;
}
.about-image .about-box-text {
  position: absolute;
  top: 270px;
  width: 100%;
  padding: 40px;
}
.about-image .about-box-text .about-title {
  margin-bottom: 60px;
}
.about-image .about-box-text .about-title .about-title-text {
  margin-bottom: 8px;
  font-size: 18px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: #e9e5cd;
  line-height: 20px;
  text-align: center;
}
.about-image .about-box-text .about-title .about-desc-text {
  margin-bottom: 8px;
  font-size: 10px;
  font-family: '';
  color: #ae864a;
  line-height: 14px;
}
.about-image .about-box-text .about-title .about-name-text {
  margin-bottom: 8px;
  font-size: 8pt;
  font-family: FZLanTingHeiS-DB1-GBK;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
  text-align: center;
}
.about-image .about-box-text .about-text {
  font-size: 16px;
  color: #ffffff;
  line-height: 1.75;
  margin-bottom: 16px;
}
.about-image .about-box-text .about-text .about-text-para {
  opacity: 0.5;
}
.about-image .about-box-text .about-text .about-text-h {
  text-align: center;
}
.about-image .about-box-text .about-slogan .about-slogan-text {
  background: url('../../images/about-slogan-bg.PNG') no-repeat;
  background-size: 100%;
  text-align: center;
  font-size: 16px;
  font-family: '';
  color: #d3bf93;
  line-height: 16px;
  height: 55px;
  width: 30vw;
  padding: 6px;
}
.about-image .about-box-text .about-slogan-left {
  background: url(../../images/about-slogan-left.PNG) no-repeat;
  /* background-attachment: fixed; */
  background-size: contain;
  text-align: center;
  line-height: 16px;
  height: 19px;
  width: 30vw;
  margin-top: 6px;
}
.about-image .about-box-text .about-slogan-right {
  background: url(../../images/about-slogan-right.PNG) no-repeat;
  /* background-attachment: fixed; */
  background-size: contain;
  text-align: center;
  line-height: 16px;
  height: 19px;
  width: 30vw;
  position: absolute;
  right: 0;
  margin-top: 6px;
}
@media screen and (max-width: 768px) {
  .about {
    height: auto;
    min-height: 1400px;
  }
  .about .about-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .about-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .about-image {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0vw;
    margin: 0;
    min-height: 1400px;
  }
  .about-box-image {
    position: absolute;
    width: 92vw !important;
    left: 4vw;
    top: 0;
    margin: 0;
  }
  .about-box-text {
    position: absolute;
    top: 500px !important;
    width: 100%;
    padding: 40px;
  }
}

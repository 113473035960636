@about: about;

.@{about} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  // height: 100vh;
  min-height: 1400px;
  position: relative;
  background: url('../../images/about-bg.png') center;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;

  & &-page {
    padding: 1px 0 0;
  }

  &-title {
    font-size: 20px;
    font-family: 'KaiTi';
    font-family: 'STKaiTi';
    color: rgb(233, 229, 205);
    line-height: 40px;
    text-shadow: 0px 2px 4px rgba(28, 20, 36, 0.15);
    -moz-transform: matrix(2.19640974433988, 0, 0, 2.19640974433988, 0, 0);
    -webkit-transform: matrix(2.19640974433988, 0, 0, 2.19640974433988, 0, 0);
    -ms-transform: matrix(2.19640974433988, 0, 0, 2.19640974433988, 0, 0);
    text-align: center;

    &-wrapper {
      width: 40%;
      max-width: 450px;
      position: relative;
      top: 30%;
      left: 24px;
      font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB',
        'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol';
    }
  }

  &-explain {
    color: #333;
    font-size: 65px;
    font-family: 'KaiTi';
    font-family: 'STKaiTi';
    color: rgb(239, 221, 184);
    line-height: 40px;
    text-align: center;
    margin-left: 150px;
    margin-bottom: 24px;
  }

  &-content {
    font-size: 18px;
    font-family: 'MicrosoftYaHeiUI';
    text-align: center;
    line-height: 20px;
    color: rgb(174, 133, 74);
    margin-left: 155px;
    margin-bottom: 48px;
  }

  &-image {
    overflow: hidden;
    width: 40%;
    max-width: 710px;
    position: absolute;
    top: 70px;
    left: calc(~'50vh - 191px');
    // right: 14px;

    .about-box {
      background-color: rgb(11, 11, 11);
      opacity: 0.502;
      height: 1200px;
      width: 100%;
    }

    .about-box-image {
      position: absolute;
      top: 0;
      width: 100%;
    }

    .about-box-text {
      position: absolute;
      top: 270px;
      width: 100%;
      padding: 40px;

      .about-title {
        margin-bottom: 60px;

        .about-title-text {
          margin-bottom: 8px;
          font-size: 18px;
          font-family: 'KaiTi';
          font-family: 'STKaiTi';
          color: rgb(233, 229, 205);
          line-height: 20px;
          text-align: center;
        }

        .about-desc-text {
          margin-bottom: 8px;
          font-size: 10px;
          font-family: '';
          color: #ae864a;
          line-height: 14px;
        }

        .about-name-text {
          margin-bottom: 8px;
          font-size: 8pt;
          font-family: FZLanTingHeiS-DB1-GBK;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
          text-align: center;
        }
      }

      .about-text {
        font-size: 16px;
        color: rgb(255, 255, 255);
        line-height: 1.75;
        margin-bottom: 16px;

        .about-text-para {
          opacity: 0.5;
        }

        .about-text-h {
          text-align: center;
        }
      }

      .about-slogan {
        .about-slogan-text {
          background: url('../../images/about-slogan-bg.PNG') no-repeat;
          // background-attachment: fixed;
          background-size: 100%;
          text-align: center;
          font-size: 16px;
          font-family: '';
          color: #d3bf93;
          line-height: 16px;
          height: 55px;
          width: 30vw;
          padding: 6px;
        }
      }

      .about-slogan-left {
        background: url(../../images/about-slogan-left.PNG) no-repeat;
        /* background-attachment: fixed; */
        background-size: contain;
        text-align: center;
        line-height: 16px;
        height: 19px;
        width: 30vw;
        margin-top: 6px;
      }

      .about-slogan-right {
        background: url(../../images/about-slogan-right.PNG) no-repeat;
        /* background-attachment: fixed; */
        background-size: contain;
        text-align: center;
        line-height: 16px;
        height: 19px;
        width: 30vw;
        position: absolute;
        right: 0;
        margin-top: 6px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .@{about} {
    height: auto;
    min-height: 1400px;

    & &-page.home-page {
      padding-top: 1px;
      padding-bottom: 0;
    }

    &-title-wrapper {
      text-align: center;
      position: static;
      width: 100%;
      margin-top: 64px;
      left: 0;
      top: 0;
    }

    &-image {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0vw;
      margin: 0;
      min-height: 1400px;
    }

    &-box-image {
      position: absolute;
      width: 92vw !important;
      left: 4vw;
      top: 0;
      margin: 0;
    }

    &-box-text {
      position: absolute;
      top: 500px !important;
      width: 100%;
      padding: 40px;
    }
  }
}

.footer2-wrapper {
  background-color: #0d1a26;
  height: 80px;
  overflow: hidden;
  .footer2 {
    height: 100%;
    padding: 0 24px;
    line-height: 80px;
    text-align: center;
    color: rgb(185, 153, 115);
    position: relative;
  }
  .gongan-link, .icp-link {
    color: rgb(185, 153, 115);
  }
  .copyright {
    float: right;
    >* {
      display: inline-block;
    }
    &-logo {
      width: 16px;
      margin-right: 8px;
      img {
        width: 100%;
      }
    }
    &-line {
      padding: 0 12px;
      margin: 0 12px;
    }
  }
  .icp {
    float: right;
    >* {
      display: inline-block;
    }
    &-link {
      padding: 0 12px;
      margin: 0 12px;
    }
  }
  .links {
    float: right;
  }

  .gongan-link {
    height: 21px;
    display: inline-block;
    margin-right: 32px;
    p {
      display: inline-block;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer2-wrapper {
    .footer2 {
      font-size: 12px;
      &.home-page {
        padding: 0;
      }
      >div {
        width: 90%;
        margin: auto;
      }
    }
  }
}

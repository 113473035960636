@content4: content4;
.@{content4}-wrapper {
  height: 100vh;
  width: 100%;
  background-image: url('../../images/business-bg-repeat.png');
  background-size: contain;
  background-attachment: fixed;
  background-position: center;
  .@{content4} {
    height: 100%;
    overflow: hidden;
    & .title-content {
      text-align: center;
    }
    & .title-h1 {
      font-size: 48px;
      font-family: 'KaiTi';
      font-family: 'STKaiti';
      color: rgb(255, 255, 255);
      line-height: 0.583;
    }
    &-block-wrapper {
      position: relative;
      .@{content4}-block {
        display: inline-block;
        padding: 2px;
        vertical-align: top;
        text-align: center;

        .@{content4}-link {
          border: 1px solid rgb(193, 153, 66);
          margin: 20px;
          background-color: rgb(17, 14, 21);
          opacity: 0.8;
        }
        .@{content4}-title {
          width: 100%;
          font-size: 36px;
          font-family: 'KaiTi';
          font-family: 'STKaiTi';
          color: rgb(239, 221, 184);
          line-height: 0.778;
        }
        .@{content4}-icon {
          display: inline-block;
          width: 100%;
          vertical-align: top;
          margin-top: 25px;
        }
        .@{content4}-text {
          width: 100%;
          text-align: center;
          vertical-align: center;
          display: inline-block;
          margin-top: 28px;
        }
        .@{content4}-text:after {
          content: '';
          display: inline-block;
          text-align: center;
          opacity: 0.502;
          width: 100px;
          margin-top: 15px;
          border-bottom: 2px solid rgb(88, 66, 36);
        }
        &.clear-both {
          clear: both;
        }
      }
      .@{content4}-block:hover {
        .@{content4}-link {
          border: 2px solid rgb(193, 153, 66);
          opacity: 1;

        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content4}-wrapper {
    height: auto;
    min-height: 1440px;
    .@{content4} {
      &-block-wrapper {
        margin: 20px auto;
        height: auto;
        .@{content4}-block {
          .@{content4}-title {
            font-size: 6vw;
          }
          &.queue-anim-leaving {
            position: relative !important;
          }
        }
      }
    }
  }
}

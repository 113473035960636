.modal-background {
  border-style: solid;
  border-width: 0.1px;
  border-color: rgb(193, 153, 66);
  background-color: #111018;
  // opacity: 0.91;
}

.ant-modal-content {
  background-color: #111018;
  color: #fff;
}

.ant-modal-close-x {
  color: #fff;
}

.ant-modal-close-icon {
  svg {
    width: 30px;
    height: 30px;
    color: #c19942;
  }
}

.ant-modal {
  top: 50px;
}

.modal-row {
  margin: 10px 60px;

  .modal-col {
    margin: 5px 0px;

    img {
      height: 70%;
      width: 70%;
      border: 1px solid rgb(193, 153, 66);
      border-radius: 2px;
      object-fit: cover;
    }
  }

  .prod-title {
    font-size: 48px;
    font-family: 'KaiTi';
    font-family: 'STKaiTi';
    color: rgb(239, 221, 184);
    line-height: 0.883;
    margin-left: 80px;
    margin-bottom: 30px;
  }

  .prod-desc {
    font-size: 30px;
    font-family: 'Adobe Heiti Std';
    color: rgba(211, 170, 108, 0.702);
    line-height: 2.4;
    font-style: italic;
    margin-left: 80px;
  }

  .prod-desc-right {
    margin-left: 200px;
  }
}

.modal-title {
  font-size: 36px;
  font-family: 'KaiTi';
  font-family: 'STKaiTi';
  color: rgb(255, 255, 255);
  line-height: 0.778;
  margin-bottom: 30px;
}

.modal-pic-row {
  margin: 3vh 0px 0;
  text-align: center;
  position: absolute;
  top: 55vh;
}

.modal-pic-list-item {
  margin: 8px;
  width: 182px;
  height: 158px;
  border: 1px solid rgb(193, 153, 66);
  border-radius: 2px;

  img {
    object-fit: cover;
    height: 156px;
  }
}

.modal-pic-list-item-active {
  border: 3px solid rgb(193, 153, 66);

  img {
    height: 152px;
  }
}

.modal-pic-list-left-arrow {
  width: 38px;
  height: 51px;
  margin-right: 14px;
  padding: 60px 0;
  float: right;
}

.modal-pic-list-right-arrow {
  width: 38px;
  height: 51px;
  margin-left: 14px;
  padding: 60px 0;
  float: left;
}

.@{content3}-title {
  font-size: 20px;
  font-family: 'Kaiti';
  font-family: 'STKaiti';
  color: #fff;
  text-align: center;
  background-color: rgb(17, 14, 21);
  opacity: 0.8;
  margin-top: -31px;

}

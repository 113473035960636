#Content3_0.ke3q35sjyt-editor_css {
}
#Nav2_0.ke3q00azjug-editor_css {
  color: rgba(0, 0, 0, 0);
  position: fixed;
  background-color: rgba(0, 21, 41, 0);
  background-image: linear-gradient(black 0%, rgba(0, 0, 0, 0) 100%);
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
  box-shadow: none;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .ke5a3jzttsb-editor_css {
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
}
#Banner2_0 .home-page > .banner2-text-wrapper > .ke5a224zlzc-editor_css {
  margin: auto auto 0;
}

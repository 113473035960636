@content3: content3;
.@{content3}-wrapper {
  height: 100vh;
  width: 100%;
  background-image: url('../../images/prod-bg.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  .@{content3} {
    height: 100%;
    overflow: hidden;
    padding-top: 0;
    margin-top: 15vh;
    & .title-content {
      text-align: center;
    }
    &-block-wrapper {
      position: relative;
      .@{content3}-block {
        display: inline-block;
        padding: 2px;
        vertical-align: top;
        .@{content3}-title {
          font-size: 20px;
          font-family: 'Kaiti';
          font-family: 'STKaiti';
          color: #fff;
          text-align: right;
          position: fixed;
          padding: 0 10px;
          bottom: 0;
          right: 0;
          background-color: rgb(17, 14, 21);
          opacity: 0.8;
        }
        .@{content3}-desc {
          display: none;
        }
        .@{content3}-icon {
          display: inline-block;
          width: 100%;
          vertical-align: top;
        }
        .@{content3}-text {
          width: 15%;
          display: inline-block;
          padding-left: 8%;
        }
        &.clear-both {
          clear: both;
        }
        .btn-more {
          display: none;
        }
      }
      .@{content3}-block-hover:hover {
        .@{content3}-title {
          font-size: 22px;
          font-family: 'Kaiti';
          font-family: 'STKaiti';
          color: #fff;
          position: absolute;
          margin: 0;
          top: 0;
          left: 0;
          background-color: rgb(17, 14, 21);
          opacity: 0.8;
        }
        .@{content3}-desc {
          display: inline-block;
          font-size: 16px;
          color: #fff;
          text-align: left;
          position: absolute;
          padding: 0 10px;
          margin: 10px;
          bottom: 40px;
          left: 0;
        }
        .btn-more {
          display: inline-block;
          position: absolute;
          padding: 0 10px;
          margin: 0 20px;
          right: 0;
          bottom: 18px;
          color: rgb(193, 153, 66);
          border: 1px solid rgb(193, 153, 66);
          background: transparent;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content3}-wrapper {
    height: auto;
    // min-height: 1680px;
    .@{content3} {
      &-block-wrapper {
        margin: 20px auto;
        height: auto;
        .@{content3}-block {
          .@{content3}-title {
            font-size: 18px;
          }
          &.queue-anim-leaving {
            position: relative !important;
          }
        }
        .@{content3}-block-hover:hover {
          .@{content3}-title {
            color: rgb(17, 14, 21);
          }
        }
      }
    }
  }
}

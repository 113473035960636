@banner2: banner2;
.@{banner2} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(100vh - 64px);
  width: 100%;
  height: ~'calc(100vh)';
  position: relative;
  border-color: #666;
  background: #fff;
  .banner-anim {
    height: 100%;
  }
  & .queue-anim-leaving {
    position: relative !important;
    width: auto;
  }
  .banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
  }
  .bg0 {
    background: url('../../images/bg0.jpg') center;
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
    width: ~'calc(100% + 100px)';
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      // background: fade(#000, 35);
      display: block;
    }
  }
  .bg1 {
    background: url('../../images/bg1.png') center;
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
    width: ~'calc(100% + 100px)';
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      // background: fade(#000, 35);
      display: block;
    }
  }
  .bg2 {
    background: url('../../images/bg2.png') center;
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
    width: ~'calc(100% + 100px)';
    left: -50px;
    &:before {
      width: 100%;
      height: 100%;
      content: '';
      // background: fade(#000, 35);
      display: block;
    }
  }
  .banner-user-elem .banner-user-title {
    font-size: 22px;
    top: 40%;
  }
  .banner-user-elem .banner-user-text {
    top: 40%;
  }
  &-page {
    position: relative;
    top: -40px;
    max-width: 1200px;
    margin: auto;
  }
  &-text-wrapper {
    display: inline-block;
    font-size: 14px;
    color: @template-text-color-light;
    text-align: left;
    .@{banner2}-title {
      left: 0;
      margin: auto;
      font-size: 56px;

      position: fixed;
      left: 10vw;
      top: 0;
      width: 400px;
      height: 100vh;

      .bg-box {
        opacity: 0.6;
        background-color: rgb(11, 11, 11);
        width: 100%;
        height: 100%;
      }

      img {
        position: absolute;
        left: 100px;
        top: 25vh;
      }
    }
    & .@{banner2}-content {
      // word-wrap: break-word;
      // margin: auto auto 20px;
      // font-weight: lighter;
      opacity: 0.6;
      width: 100vw;

      img {
        position: absolute;
        right: 30vw;
        width: 30vw;
      }
    }
  }

  &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    left: 50%;
    margin-left: -12px;
    color: @template-text-color-light;
  }
}

.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 767px) {
  .@{banner2} {
    .bg0 {
      background: url('../../images/bg0-m.jpg') center 0 no-repeat;
      height: 100vh;
      background-size: cover;
    }
    .bg2 {
      background: url('../../images/bg2-m.jpg') center 0 no-repeat;
      height: 100vh;
      background-size: 100% 100%;
    }
    &-text-wrapper {
      width: 100%;
      left: 0;
      margin: auto;
      padding: 0;
      text-align: center;
      display: block;
      .logo {
        width: 90%;
        left: 0;
      }

      .@{banner2}-title {
        margin: auto;
        // font-size: 56px;

        // position: fixed;
        // left: cal(~'50vw - 82px');
        width: 260px;
        height: 420px;

        margin: auto;
        position: absolute;
        top: 90vh;
        left: 0;
        right: 0;
        bottom: 0;

        .bg-box {
          border: 1px solid #e5b673;

          opacity: 0.8;
          background-color: rgb(11, 11, 11);
          overflow: hidden;

          .bg-box-bg {
            opacity: 0.5;
            background: inherit;

            padding: -1px;

            width: 100%;
            height: 100%;
          }
        }

        .bg-img {
          border: 1px solid #e5b673;

          position: absolute;

          left: 10%;
          right: 10%;
          top: 5%;
          width: 81%;
          height: 90%;
          // margin: 5px;
          img {
            position: absolute;
            top: 10%;
            left: 0;
            margin: 20%;
            width: 60%;
            height: 60%;
          }
        }
      }
      & .@{banner2}-content {
        // word-wrap: break-word;
        // margin: auto auto 20px;
        // font-weight: lighter;

        img {
          position: unset;
          width: 80vw;
          top: 0;
          left: 0vw;
          max-width: 456px;
        }
      }
    }
  }
}
